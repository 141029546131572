<template>
  <div>
  <h2>Dashboard</h2>
  <h4>(TBD)</h4>
  </div>
</template>

<script>
export default {
    name: 'User_Dashboard'
}
</script>

<style lang="scss" scoped>

</style>
